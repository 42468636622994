import React from 'react';
import { Helmet } from 'react-helmet-async';

function Services() {
    return (
        <>
            <Helmet>
                <title>Solar Care Solutions | Services</title>
                <meta name="robots" content="index,follow" />
                <meta name="description"
                    content="Solar Care Solutions offers a wide range of premium solar products including inverters, batteries, panels, and more."
                />
                <link rel='canonical' href='/' />
            </Helmet>
        <section className="bg-indigo-500 pb-8 mt-20">
            <div className='bg-white p-4'>
                <h1 className="text-3xl font-bold mb-5">Our Services</h1>

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                    {renderServiceSection("Inverter Battery", ["EXIDE", "LUMINOUS", "MATRIX", "V-GUARD", "LIVGUARD", "D-LIGHT", "CENTRIA", "SF SONIC", "OKAYA", "MICROTEK", "ADVON", "UTL"])}
                    {renderServiceSection("Inverter", ["EXIDE", "LUMINOUS", "MICROTEK", "LIVGUARD", "V-GUARD", "D-LIGHT", "ADVON", "EAPRO"])}
                    {renderServiceSection("Solar Panels", ["LOOM SOLAR", "LUMINOUS", "EXIDE", "MICROTEK", "LIVGUARD", "V-GUARD", "MATRIX", "EASTMAN", "INA", "ADVON", "UTL"])}
                    {renderServiceSection("Solar Battery", ["LUMINOUS", "EXIDE", "MICROTEK", "LIVGUARD", "V-GUARD", "MATRIX", "EASTMAN", "INA", "ADVON", "UTL"])}
                    {renderServiceSection("SOLAR HYBRID INVERTER", ["Solar Hybrid PCU", "SOLAR ON GRID PCU", "SOLAR OFF GRID PCU", "SOLAR MANAGEMENT UNIT", "SOLAR MC4 CONNECTOR", "SOLAR WIRE", "SOLAR STRUCTURE"])}
                    {renderServiceSection("On Grid & Off Grid Solar Solution", ["3 kw to 50 kw", "HITACHI", "LIVGUARD", "LUMINOUS", "EXIDE", "LOOM", "UTL", "Delta"])}
                </div>

                <div className="mt-10 border-t pt-6 background ">
                    <h2 className="text-2xl font-bold mb-4">Contact Us</h2>
                    <p className="mb-2">Address: Sikarpur, Gorakhpur roads, Chauraha, Gorakhpur Rd, near Bharat Petrol Pump, Maharajganj, Uttar Pradesh 273302</p>
                    <p className="mb-2">Phone number: 919455338201/ +91 96705 12432 / 063937 28923</p>
                </div>
            </div>
        </section>
        </>
    );
}

function renderServiceSection(title, items) {
    return (
        <div className="bg-gray-100 p-5 rounded-lg">
            <h2 className="text-xl font-semibold mb-4">{title}</h2>
            <ul className="list-disc pl-6">
                {items.map(item => (
                    <li key={item}>{item}</li>
                ))}
            </ul>
        </div>
    );
}

export default Services;
