import React, { Fragment } from 'react';
import { BrowserRouter as Router, Route, Routes,Redirect } from 'react-router-dom';
import Home from './pages/home';
import TermsAndConditions from './pages/termsandcondition';
import PrivacyAndPolicy from './pages/privacypolicy';
import Nav from './components/navbar/Nav';
import { Footer } from './components/Footer/Footer';
import Services from './pages/services';
import Products from './pages/products';
import Contact from './pages/contact';
import { Provider } from 'react-redux';
import store from './store/configureStore'

const App = () => {

  return (
       <Provider store={store}>
            <Router>
      <Fragment>
        {/* Other components like <Header /> can go here */}
      <div className='bg-indigo-500'>
          <Nav />
       <div>
         <Routes>
          <Route path="/" exact element={<Home />} />
           <Route path="/products" element={<Products/>} />
           <Route path="/contacts" element={<Contact/>} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/privacy-and-policy" element={<PrivacyAndPolicy />} />
          <Route path="/services" element={<Services />} />
           <Route path='*' element={<Home/>}/>
          {/* You can have a 404 or "Not Found" route here as well if needed */}
        </Routes>
        {/* Components like <Footer /> can go here */}
        <Footer />
       </div>
        
      </div>
      </Fragment>
    </Router>
    </Provider>

  );
};

export default App;
