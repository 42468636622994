import React, { useEffect } from "react";
import "../../index.css";
import Card from "./card";
import 'react-loading-skeleton/dist/skeleton.css'
import CardSkeleton from "../Skeleton/card.skeleton";
import { useDispatch, useSelector } from 'react-redux';
import { fetchProducts } from '../../actions/productActions';

export const Product = () => {
  const dispatch = useDispatch();
  const products = useSelector((state) => state.products);
  const loading = useSelector((state) => state.loading);
  useEffect(() => {
    if (!products.length) { dispatch(fetchProducts()); }
  }, [dispatch, products]);
  return (
    <div className="flex flex-wrap align-center justify-center self-center gap-8">
      {loading ? [1, 2, 3, 4, 5, 6, 7, 8].map((item) => {
        return (
          <CardSkeleton item={item} />
        )
      }) : products.map((item, index) => {
        return (
          <Card index={index} item={item} />
        )
      }) 
      }
    </div>
  );
};
