import React, { useState } from 'react';
import Button from './Button';
import SolareCareLogo from '../../assets/Solarcare.png'
import { Link } from 'react-router-dom';

const Nav = () => {
  let Links = [
    { name: 'HOME', link: '/' },
    { name: 'SERVICE', link: '/#service' },
    { name: 'GALLERY', link: '/#gallery' },
    { name: 'PRODUCTS', link: '/products' },
    { name: 'CONTACTS', link: '/contacts' },
  ];
  let [open, setOpen] = useState(false);

  return (
    <div className='shadow-md w-full relative'>
      <div className='md:flex items-center justify-between bg-white py-4 md:px-10 px-7 sticky z-20 top-0'>
        <div
          className=''>
          <Link to="/">
          <img src={SolareCareLogo} className='h-12 w-28 object-contain' alt='Logo' />
          </Link>
        </div>

        <div
          onClick={() => setOpen(!open)}
          className='text-3xl absolute right-8 top-6 cursor-pointer md:hidden'>
          <ion-icon name={open ? 'close' : 'menu'}></ion-icon>
        </div>

        <ul
          className={`md:flex md:items-center md:pb-0 pb-12 absolute md:static bg-white md:z-auto z-20 left-0 w-full md:w-auto md:pl-0 pl-9 transition-all duration-50 ease-in ${
            open ? 'top-20 ' : 'top-[-490px]'
            }`} onClick={() => setOpen(!open)}>
          {Links.map(link => (
            <li key={link.name} className='md:ml-8 text-xl md:my-0 py-4'>
              <Link
                to={link.link}
                className='text-gray-800 hover:text-gray-400 duration-500'>
                {link.name}
              </Link>
            </li>
          ))}
          <a href='tel:+919455338201'>
            <Button>
              <div className='flex flex-row items-center space-x-2'>
                <ion-icon name='call-outline'></ion-icon>
                <div>Call Now</div>
              </div>
            </Button>
          </a>
        </ul>
      </div>
    </div>
  );
};

export default Nav;
