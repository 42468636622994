import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

const CardSkeleton = ({ item }) => {
    return (
        <SkeletonTheme index={item} color="#c4c4c4" highlightColor="#e8e3e3">
            <div className="w-[70vw] md:w-[332px] h-[433px] rounded-md overflow-hidden shadow-lg bg-white p-3">
                <center className="">
                    <div className="h-40">
                        <Skeleton variant="rectangular" width="100%" height="100%" borderRadius="10px" />
                    </div>
                    <div className="px-6 py-4">
                        <Skeleton variant="text" width="100%" height="24" />
                        <Skeleton variant="text" width="100%" height="24" />
                    </div>
                    <div className="h-16 mt-4">
                        <Skeleton variant="rectangular" width="100%" height="100%" borderRadius="10px" />
                    </div>
                </center>
            </div>
        </SkeletonTheme>
    )
}

export default CardSkeleton
