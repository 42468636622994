// productActions.js
import productService from '../services/productService';

export const fetchProductsRequest = () => {
  return {
    type: 'FETCH_PRODUCTS_REQUEST',
  };
};

export const fetchProductsSuccess = (products) => {
  return {
    type: 'FETCH_PRODUCTS_SUCCESS',
    payload: products,
  };
};

export const fetchProductsFailure = () => {
  return {
    type: 'FETCH_PRODUCTS_FAILURE',
  };
};

export const fetchProducts = () => {
  return (dispatch) => {
    dispatch(fetchProductsRequest());
    productService
      .getProducts()
      .then((response) => {
        dispatch(fetchProductsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchProductsFailure());
      });
  };
};
