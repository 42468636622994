import React from 'react';
import { Helmet } from 'react-helmet-async';

const TermsAndConditions = () => {
    return (
        <>
            <Helmet>
                <title>Solar Care Solutions | Terms</title>
                <meta name="robots" content="index,follow" />
                <meta name="description"
                    content="Please read the following terms and conditions before purchasing."
                />
                <link rel='canonical' href='/' />
            </Helmet>

            <div className="bg-gray-100 min-h-screen p-8 my-20">
            <div className="max-w-4xl mx-auto bg-white p-6 rounded-md shadow-md">
                <h1 className="text-2xl font-semibold mb-6">Terms and Conditions</h1>

                <p className="mb-4">Please read the following terms and conditions before purchasing any of the products listed below:</p>

                <section className="mb-6">
                    <h2 className="text-xl font-bold mb-4">Products We Sell</h2>

                    <div className="grid grid-cols-2 gap-4">
                        <div>
                            <h3 className="font-semibold mb-2">Inverter Battery</h3>
                            {["EXIDE", "LUMINOUS", "MATRIX", "V-GUARD", "LIVGUARD", "D-LIGHT", "CENTRIA", "SF SONIC", "OKAYA", "MICROTEK", "ADVON", "UTL"].map(item => (
                                <p key={item} className="mb-1">- {item}</p>
                            ))}
                        </div>
                        <div>
                            <h3 className="font-semibold mb-2">Inverter</h3>
                            {["EXIDE", "LUMINOUS", "MICROTEK", "LIVGUARD", "V-GUARD", "D- LIGHT", "ADVON", "EAPRO"].map(item => (
                                <p key={item} className="mb-1">- {item}</p>
                            ))}
                        </div>
                    </div>
                </section>

                {/* ... Other product sections can be added in a similar way ... */}

                <section className="mb-6">
                    <h2 className="text-xl font-bold mb-4">Contact Information</h2>
                    <p className="mb-2"><span className="font-semibold">Address:</span> Sikarpur, Gorakhpur roads, Chauraha, Gorakhpur Rd, near Bharat Petrol Pump, Maharajganj, Uttar Pradesh 273302</p>
                    <p className="mb-2"><span className="font-semibold">Phone number:</span>919455338201/ +91 96705 12432 / 063937 28923</p>
                    </section>
            </div>
        </div>
        </>
    );
}

export default TermsAndConditions;
