import {SecondaryButton} from "../navbar/Button"

const Card = ({ item, index }) => {
    console.log(item);
    return (
        <div
            className="max-w-[332px] min-h-[360px] rounded-md overflow-hidden shadow-lg bg-white pb-3 flex flex-col"
            key={index}
        >
            <img
                className="w-full h-64 object-contain bg-none"
                src={item.product_image}
                alt="Sunset in the mountains"
            />
            <div className="px-6 py-4 flex-grow text-center">
                <div className="font-bold text-xl mb-2 mx-6">{item.product_name}</div>
                <div>₹&nbsp;{item?.product_price}</div>
                <p className="text-gray-700 text-base">{item.product_description}</p>
            </div>
            <div className="shake flex justify-center pb-3">
                <div className="">
                    <a href="tel:+919455338201">
                        <SecondaryButton>
                            <div className="flex flex-row items-center gap-2">
                                <ion-icon name="call-outline"></ion-icon>
                                <div style={{ fontSize: "0.7em" }}> Call Now to Buy</div>
                            </div>
                        </SecondaryButton>
                    </a>
                </div>
            </div>
        </div>    
    )
}

export default Card