import React from 'react';
import { Helmet } from 'react-helmet-async';

const PrivacyAndPolicy = () => {
    return (
        <>
            <Helmet>
                <title>Solar Care Solutions | Privacy</title>
                <meta name="robots" content="index,follow" />
                <meta name="description"
                    content="At Solar Care Solutions, we respect the privacy of our users and are committed to protecting it through our compliance with this policy."
                />
                <link rel='canonical' href='/' />
            </Helmet>
            <div className="bg-white p-10 mt-10 shadow-md my-20">
            <h1 className="text-2xl font-bold mb-6">Privacy & Policy</h1>

                <p className="mb-4">At Solar Care Solutions, we respect the privacy of our users and are committed to protecting it through our compliance with this policy. This policy describes the types of information we may collect from you or that you may provide when you visit our website and our practices for collecting, using, maintaining, protecting, and disclosing that information.</p>

            <h2 className="text-xl font-semibold mt-4 mb-4">Products We Offer:</h2>

            <div className="grid grid-cols-2 gap-4">
                <div>
                    <h3 className="font-bold mb-2">Inverter Battery Brands</h3>
                    <ul>
                        {["EXIDE", "LUMINOUS", "MATRIX", "V-GUARD", "LIVGUARD", "D-LIGHT", "CENTRIA", "SF SONIC", "OKAYA", "MICROTEK", "ADVON", "UTL"].map(brand => (
                            <li key={brand} className="mb-1">- {brand}</li>
                        ))}
                    </ul>
                </div>
                {/* Add similar blocks for other product types here */}
            </div>

            <div className="mt-8">
                <h2 className="text-xl font-semibold mb-4">Contact Us:</h2>
                <p className="mb-2"><span className="font-semibold">Address:</span> Sikarpur, Gorakhpur roads, Chauraha, Gorakhpur Rd, near Bharat Petrol Pump, Maharajganj, Uttar Pradesh 273302</p>
                <p className="mb-2"><span className="font-semibold">Phone:</span>919455338201 or +91 96705 12432 or 063937 28923</p>
            </div>

            {/* Add more sections related to your privacy policy below */}

            </div></>
    );
}

export default PrivacyAndPolicy;
