import React from 'react'
import 'flowbite'
import { Helmet } from 'react-helmet-async'

const Contact = () => {
    return (
        <>
            <Helmet>
                <title>Solar Care Solutions | Contacts</title>
                <meta name="robots" content="index,follow" />
                <meta name="description"
                    content="Solar Care Solutions offers a wide range of premium solar products including inverters, batteries, panels, and more. Explore our collection and make a sustainable choice today."
                />
                <link rel='canonical' href='/' />
            </Helmet>
        <div className='my-20 py-20 bg-white px-10 md:space-y-0 space-y-10 flex md:flex-row flex-col md:justify-between justify-center items-center'>
            <div>
                <form className="max-w-lg mx-auto flex flex-col justify-center">
                    <label for="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your email</label>
                    <div className="relative">
                        <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                            <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 16">
                                <path d="m10.036 8.278 9.258-7.79A1.979 1.979 0 0 0 18 0H2A1.987 1.987 0 0 0 .641.541l9.395 7.737Z" />
                                <path d="M11.241 9.817c-.36.275-.801.425-1.255.427-.428 0-.845-.138-1.187-.395L0 2.6V14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2.5l-8.759 7.317Z" />
                            </svg>
                        </div>
                        <input type="text" id="email-address-icon" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="yourmail@gmail.com" />
                    </div>
                    <textarea id="message" rows="4" className=" my-4 block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Leave a comment..."></textarea>
                    <button type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Submit</button>
                    <p id="helper-text-explanation" className="mt-2 text-sm text-gray-500 dark:text-gray-400">We’ll never share your details. Read our <a href="#" className="font-medium text-blue-600 hover:underline dark:text-blue-500">Privacy Policy</a>.</p>
                </form>
            </div>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3548.762715364753!2d83.69234107563115!3d27.19519364789368!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39940564b528cc6b%3A0x8b4f69cb799e1d82!2sSolar%20Care%20Solutions!5e0!3m2!1sen!2sin!4v1703420928304!5m2!1sen!2sin" height="450" style={{ border: "2px", borderColor: "black", borderRadius: '20px', }} className='md:w-1/2 w-full' allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title='Solar Care Solutions'></iframe>
        </div>
        </>
    )
}

export default Contact