import React from "react";
import Gpay from "../../assets/gpay.png";
import Paytm from "../../assets/paytm.png";
import GpayB from "../../assets/gpaybtn.png";
import PaytmB from "../../assets/paytmbtn.png";
import 'flowbite';

export const Payment = () => {
  return (
    <>
      <div className="flex justify-center my-20">
        <div id="accordion-color" data-accordion="collapse" data-active-classes="bg-blue-100 dark:bg-gray-600 text-blue-600 dark:text-white max-w-lg">
          <h2 id="accordion-color-heading-1">
            <button type="button" className="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200 rounded-t-xl focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800 dark:border-gray-700 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-color-body-1" aria-expanded="true" aria-controls="accordion-color-body-1">
              <span>1. Google Pay Payment Gateway</span>
              <svg data-accordion-icon className="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
              </svg>
            </button>
          </h2>
          <div id="accordion-color-body-1" className="hidden" aria-labelledby="accordion-color-heading-1">
            <div className="p-5 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900">
              <div className="flex md:flex-row flex-col justify-center">
      <div className="m-2 flex flex-col items-center">
        <img
          src={Gpay}
          alt="gpay"
                    className="border-2 border-black rounded-md h-80 w-full"
        />
        <a href="upi://pay?pa=9670512432@okbizaxis&pn=Maa Vaishno Power Solutions&mc=5732&aid=uGICAgIDtuIPFIQ&tr=BCR2DN6T2WJKD2Y2&cu=INR">
          <button className="h-10 w-24 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded my-4 ">
            <img src={GpayB} alt="gpay" className="rounded-md " />
          </button>
        </a>
        <a href={Gpay} download>
          <button className="h-10 w-24 bg-blue-500 hover:bg-blue-700 text-white font-bold rounded  ">
            Download
          </button>
        </a>
                </div>
    </div>
            </div>
          </div>
        </div>

        {/* real shit */}
      </div>


    </>
  );
};
