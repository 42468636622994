import React from 'react'
import { Slider } from '../components/slides/Slider'
import { Product } from '../components/Product/Product'
import { Payment } from '../components/Payment/Payment'
import { Helmet } from 'react-helmet-async'

const Products = () => {
    return (
        <>
            <Helmet>
                <title>Solar Care Solutions | Products</title>
                <meta name="robots" content="index,follow" />
                <meta name="description"
                    content="Solar Care Solutions offers a wide range of premium solar products including inverters, batteries, panels, and more. Explore our collection and make a sustainable choice today."
                />
                <link rel='canonical' href='/' />
            </Helmet>
            <div className="pb-8">
                <div className="">
                    <div className="m-2 border-2 rounded-md bg-white">
                        <Slider />
                    </div>
                    <div className="flex justify-center self-center my-8 mx-3">
                        <Product />
                    </div>
                    <div className="my-2 bg-white py-8">
                        <center className="font-bold text-3xl">
                            <h2>Payment Gateways</h2>
                        </center>
                        <Payment />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Products
