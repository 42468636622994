// productService.js
import axios from 'axios';

const baseURL = 'https://mvps-backend-6q5y.onrender.com';

const productService = {
  getProducts: () => axios.get(`${baseURL}/products`),
  // Add more API calls if needed
};

export default productService;

