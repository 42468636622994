import { Carousel } from 'flowbite-react';
import React from 'react';
import batt from '../../assets/batt.jpeg';
import ups from '../../assets/ups.jpeg';
import epar1 from '../../assets/epar1.jpeg';
import epar2 from '../../assets/epar2.jpeg';

export const Slider = () => {
  return (
    <div className='h-56 sm:h-64 xl:h-80 2xl:h-96'>
      <Carousel>
        <img
          src={epar1}
          alt='...'
          className='border-2 rounded-md object-contain'
        />
        <img
          src={batt}
          alt='...'
          className='border-2 rounded-md object-contain'
        />
        <img
          src={epar2}
          alt='...'
          className='border-2 rounded-md object-contain'
        />
        <img
          src={ups}
          alt='...'
          className='border-2 rounded-md object-contain'
        />
      </Carousel>
    </div>
  );
};
