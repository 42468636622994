import HeroImage from '../assets/hero-Image.jpg'
import { Helmet } from 'react-helmet-async'
import testimonial from "../assets/testimonial.jpg"
import WhoAreWe from "../assets/whoAreWe.png"
import WhyUs from "../assets/WhyUs.png"
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FaAngleDoubleLeft, FaAngleDoubleRight } from "react-icons/fa";
import { useEffect, useRef, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import Data1 from "../assets/testimonial/testimonial.json"

const Home = () => {
    const contentRef = useRef(null);
    const clicked = (direction) => {
        const contentElement = contentRef.current;

        if (contentElement) {
            // Smooth scrolling
            contentElement.scrollTo({
                left: direction === 'right' ? contentElement.scrollLeft + 300 : contentElement.scrollLeft - 300,
                behavior: 'smooth',
            });
        }
    };

    const location = useLocation();
    const serviceSectionRef = useRef(null);
    const gallerySectionRef = useRef(null);

    useEffect(() => {
        // Scroll to the target section when the component mounts
        if (location.hash === '#service' && serviceSectionRef.current) {
            serviceSectionRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        if (location.hash === '#gallery' && gallerySectionRef.current) {
            gallerySectionRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [location]);

    const Data = [{
        id: 1,
        link:'#',
        icon: './assets/OffGrid-icon-1.jpeg',
        heading: 'Off-Grid Solar Systems',
        description: 'Our off-grid solar systems are designed to provide reliable and sustainable power for your home or business.',
        image: './assets/OffGrid.jpeg'
    }, {
        id: 2,
        link:'#',
        icon: './assets/OnGrid-icon2.svg',
        heading: 'On-Grid Solar Systems',
        description: 'Generate your own electricity and reduce dependence on the grid.',
        image: './assets/onGrid.jpeg'
    },
    {
        id: 3,
        link:'#',
        icon: './assets/SolarInverters.svg',
        heading: 'Solar Inverters & Charge Controllers',
        description: 'Ensure reliable power delivery to your devices with our high efficiency inverter systems.',
        image: './assets/inverter.jpeg'
    },
    {
        id: 4,
        link:'#',
        icon: './assets/waterpump.svg',
        heading: 'Solar Water Pumps',
        description: 'Access clean water for irrigation and other needs without reliance on fossil fuels.',
        image: './assets/waterpump.jpg'

    },
    {
        id: 5,
        link:'#',
        icon: './assets/batteries.svg',
        heading: 'Batteries',
        description: 'High-performance, long-lasting energy storage for demanding applications.',
        image: './assets/Batteries.png'
    },
    {
        id: 6,
        link:'#',
        icon: './assets/Home-Lighting.svg',
        heading: 'Solar Home Lighting',
        description: 'Provide light to your home with our affordable solar lights.',
        image: './assets/Solar-Home.jpg'
    },
    {
        id: 7,
        link:'#',
        icon: './assets/EVICON.svg',
        heading: 'EV Charging',
        description: 'Power up your electric vehicle effortlessly with our convenient EV charging solutions.',
        image: './assets/EVCharging.jpeg'
    },
    {
        id: 8,
        link:'#',
        icon: './assets/fansicon.svg',
        heading: 'Fans',
        description: 'Stay cool while reducing your carbon footprint with our solar-powered ceiling fans.',
        image: './assets/fans.jpeg'
    },
    {
        id: 9,
        link:'/services',
        icon: './assets/others.svg',
        heading: 'Others',
        description: 'Click here to see others services which we provide.',
        image: './assets/NAMOsolar.jpg'
    }
    ]
   console.log(Data1);
    useEffect(() => {
        const valueDisplayElements = document.querySelectorAll(".num");
        const interval = 5000;

        valueDisplayElements.forEach((valueDisplayElement) => {
            let startValue = 0;
            let endValue = parseInt(valueDisplayElement.getAttribute('data-val'));
            let duration = Math.floor(interval / endValue);

            let counter = setInterval(() => {
                startValue += 1;
                valueDisplayElement.textContent = startValue;

                if (startValue === endValue) {
                    clearInterval(counter);
                }
            }, duration);

            // Cleanup the interval on component unmount
            return () => clearInterval(counter);
        });
    }, []);
    return (
        <>
            <Helmet>
                <title>Solar Care Solutions | Home</title>
                <meta name="robots" content="index,follow" />
                <meta name="description"
                    content="Solar Care Solutions offers a wide range of premium solar products including inverters, batteries, panels, and more. Explore our collection and make a sustainable choice today."
                />
                <link rel='canonical' href='/' />
            </Helmet>


            <div className='pb-8'>
                {/* Hero  */}
                <div className="md:h-[600px] h-[300px] w-full relative">
                    <img src={HeroImage} alt="" className='object-fill md:h-[600px] h-[300px] w-full absolute z-5' />
                    <div className="absolute z-10 px-4 py-3 w-full h-full text-center flex flex-col items-center">
                        <div className='my-auto'>
                            <h1 className="text-slate-50 font-semibold md:text-5xl text-xl font-black"> FORGING A PATH TO SUSTAINABLE TOMORROWS </h1>
                            <p className="text-slate-50 font-medium md:text-2xl text-sm mt-2">
                                Igniting creativity to sculpt an eco-conscious India
                            </p>
                        </div>
                    </div>
                </div>
                {/* Hero  */}
                {/* Testimonial */}
                <div className='bg-black text-white flex md:flex-row flex-col flex-wrap md:space-x-20 space-x-0 md:gap-y-0 gap-y-10 md:py-0 py-10 items-center text-center'>
                    <div className='h-28 w-28 flex-1'><img src={testimonial} alt='testimonial' className='h-full w-full'/></div>
                    <div className='flex flex-col items-center flex-1'>
                        <div className='md:text-5xl text-2xl font-medium'><span className='num' data-val='1500'>000</span>+</div>
                        <div className='md:text-xl text-base font-medium text-indigo-500 mt-2'>PROJECTS</div>
                    </div>
                    <div className='flex flex-col items-center flex-1'>
                        <div className='md:text-5xl text-2xl font-medium'><span className='num' data-val='129'>000</span>+</div>
                        <div className='md:text-xl text-base font-medium text-indigo-500 mt-2'>CLIENTS</div>
                    </div>
                    <div className='flex flex-col items-center flex-1'>
                        <div className='md:text-5xl text-2xl font-medium'><span className='num' data-val='50'>00</span>+</div>
                        <div className='md:text-xl text-base font-medium text-indigo-500 mt-2'>TEAM</div>
                    </div>
                    <div className='flex flex-col items-center flex-1'>
                        <div className='md:text-5xl text-2xl font-medium'><span className='num' data-val='70'>00</span>+</div>
                        <div className='md:text-xl text-base font-medium text-indigo-500 mt-2'>CITYS IN UP & BIHAR</div>
                    </div>
                    <div className='flex flex-col items-center flex-1'>
                        <div className='md:text-5xl text-2xl font-medium'><span className='num' data-val='10'>00</span>+</div>
                        <div className='md:text-xl text-base font-medium text-indigo-500 mt-2'>YEARS OF SUCCESS </div>
                    </div>
                </div>
                {/* who are we */}
                <div className='px-10 flex pt-20 md:flex-row flex-col-reverse md:justify-between justify-center md:gap-y-0 gap-y-10  items-center'>
                    <div><img src={WhoAreWe} alt='Who are we' /></div>
                    <div className='flex flex-col space-y-10 max-w-xl text-white md:text-start text-center'>
                        <div className='md:text-6xl text-3xl md:font-semibold font-bold'>Who Are We ?</div>
                        <div className='md:text-2xl text-lg font-normal text-slate-100' style={{ lineHeight: "120%%" }}>Welcome to Solar Care, where we are dedicated to revolutionizing the world by harnessing the power of renewable energy. Our commitment lies in providing innovative and sustainable solutions to meet your energy needs. As a frontrunner in the industry, we specialize in a diverse range of services aimed at creating a greener and more eco-friendly future.</div>
                    </div>
                </div>
                {/* why us */}
                <div className='px-10 flex pt-20 md:flex-row-reverse flex-col-reverse md:justify-between justify-center md:gap-y-0 gap-y-10 items-center'>
                    <div><img src={WhyUs} alt='Who are we' /></div>
                    <div className='flex flex-col space-y-10 max-w-xl text-white'>
                        <div className='md:text-6xl text-3xl md:font-semibold font-bold md:text-start text-center'>why Us ?</div>
                        <div className='text-2xl font-medium text-slate-100'>
                            {/* Start */}
                            <div>
                                <Accordion sx={{fontSize:'16px'}}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography sx={{fontWeight:'600'}}>1. Client Satisfaction</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            Experience unconditional, persistent guidance coupled with consistent support and assistance. At Solar Care, client satisfaction is not just a goal; it's our commitment. We prioritize meaningful engagement to ensure your journey towards sustainable energy is seamless and satisfying.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                    >
                                        <Typography sx={{fontWeight:'600'}}>2. Premium Products</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            Choose Solar Care for high-quality solar panels, inverters, and batteries sourced from renowned brands. Our commitment to reliability ensures that you receive top-notch products that contribute to the efficiency and longevity of your solar system.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                    >
                                        <Typography sx={{fontWeight:'600'}}>3. Support and Maintenance</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            Solar Care is not just a one-time service provider. We offer comprehensive support, including ongoing maintenance, to keep your solar system running smoothly. Count on us for continued assistance even after your system is up and running.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                    >
                                        <Typography sx={{fontWeight:'600'}}>4. Quality and Affordability</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            At Solar Care, we believe in delivering high-end, tech-driven solutions that guarantee exceptional performance, excellent durability, and long-lasting efficiency. Our commitment to quality ensures that you not only receive cutting-edge solar products but also enjoy affordability without compromising on excellence.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                    >
                                        <Typography sx={{fontWeight:'600'}}>5. Solutions for Your Needs</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            Enjoy the flexibility of customizable solutions designed to meet your specific needs and budget. Solar Care understands that each customer is unique, and we take pride in crafting solar systems that align perfectly with your requirements.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            {/* End */}
                        </div>
                    </div>
                </div>
                {/* our services */}
                <div className='flex flex-col px-10 pt-20 text-white' ref={serviceSectionRef}>
                    <div className='md:text-6xl text-3xl md:font-semibold font-bold text-center'>Our Services</div>
                    <div className='grid md:grid-cols-3 grid-cols-1 gap-4 pt-20'>
                        {Data.map((item) => (
                          <Link to={item.link}>
                            <div className='border rounded-lg overflow-hidden'>
                                <div key={item.id} className="col-span-1 bg-gray-200">
                                    <div
                                        style={{ backgroundImage: `url(${item.image})` }}
                                        className={`w-full h-80 bg-no-repeat bg-white bg-contain object-contain bg-center`}>

                                        <div className="w-full h-full backdrop-brightness-50 flex flex-col justify-center items-center">
                                            <div className='p-6 flex self-start top-0 absolute'><img src={item.icon} alt="" className='h-10 w-10' /></div>
                                            <div className="text-white w-1/2 flex flex-col self-start px-10 w-full"><div className='font-semibold md:text-2xl text-xl'>{item.heading}</div><div className='mt-2 md:text-sm text-xs'>{item.description}</div></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </Link>
                        ))}
                    </div>
                </div>
                {/* Projects */}
                <div className='flex flex-col px-10 pt-20 text-white' ref={gallerySectionRef}>
                    <div className='md:text-6xl text-3xl md:font-semibold font-bold text-center'>Projects</div>
                    <div className='text-xl font-semibold text-center my-3'>Assignments Undertaken</div>
                    <p className='text-center text-base font-normal'>At Solar Care Solutions, we illuminate your path to a greener future with expertise and heart. Our commitment to sustainable energy is powered by a passion for excellence, ensuring you not only receive top-notch service but also a partner who genuinely cares about your journey toward a brighter, eco-friendly tomorrow.</p>
                    <div ref={contentRef} className='flex flex-row space-x-4 pt-20 overflow-x-scroll w-full scroll'>
                        {Data1.map((item) => (
                            <div className='border rounded-lg overflow-hidden min-w-[320px]'>
                                <div key={item.id} className="bg-gray-200">                     
                                    <div
                                        style={{ backgroundImage: `url(${item.icon})` }}
                                        className={`w-full h-96 bg-no-repeat bg-white bg-cover object-contain bg-center`}>
                                        <div className="opacity-0 hover:opacity-100 transition-opacity flex w-full h-full backdrop-brightness-50 flex-col justify-center items-center">
                                            <div className="text-white text-center px-10 w-full">
                                                <div className='mt-2 text-xl font-bold'>{item.name}</div>
                                                <div className='mt-2 text-lg font-medium'>{item.address}</div>
                                                <div className='mt-2 text-sm font-normal'>{item.type}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className='my-10 flex flex-row md:self-end self-center items-center space-x-10'>
                        <FaAngleDoubleLeft className='h-10 w-10 text-gray-400' onClick={() => { clicked('left') }} />
                        <FaAngleDoubleRight className='h-10 w-10 text-black' onClick={() => { clicked('right') }} />
                    </div>
                </div>
            </div>


        </>
    )
}

export default Home